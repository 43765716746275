import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  catchError,
  from,
  map,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import {settingsActions} from './actions';
import {NGXLogger} from 'ngx-logger';
import {EStorageKey} from '../../enums/storage-key.enum';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from './services/settings.service';
import {EMessageType} from '../../enums/message-type.enum';
import {Store} from '@ngrx/store';
import {timeActions} from '../time/actions';
import {StorageService} from '../../services/storage.service';
import {selectLogs, selectSettings} from './reducer';
import {selectServerTime} from '../time/reducer';
import {selectAccountFullName} from '../account/selectors/account.selectors';
import {selectLanguage} from './selectors/settings.selectors';
import {alertActions} from 'src/app/features/alert/store/actions';

@Injectable()
export class SettingsFeatureEffects {
  fetchSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.fetchSettings),
      switchMap(() => {
        this.logger.info('Fetching Settings from locale store');
        return from(
          this.storage.get(EStorageKey.SETTINGS).then((value) => {
            return value;
          }),
        );
      }),
      map((settings) => {
        if (settings) {
          return settingsActions.fetchSettingsSuccess({settings: settings});
        } else {
          this.logger.info('No settings found in locale store');
          return {type: 'NO_ACTION'};
        }
      }),
      catchError((error) => {
        this.store.dispatch(alertActions.showError({error: error}));
        return of(settingsActions.stopLoading());
      }),
    ),
  );

  fetchSettingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.fetchSettingsSuccess),
      map((action) => {
        this.store.dispatch(
          timeActions.useCustomTime({
            useCustomTime: action.settings.useCustomTime,
          }),
        );
        return settingsActions.setLanguage({
          language: action.settings.language,
        });
      }),
      catchError((error) => {
        this.store.dispatch(alertActions.showError({error: error}));
        return of(settingsActions.stopLoading());
      }),
    ),
  );

  saveSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(settingsActions.saveSettings),
        tap(() => {
          this.logger.info('Saving settings in locale store');
        }),
        withLatestFrom(this.store.select(selectSettings)),
        switchMap(([action, settings]) => {
          return this.settingsService.saveSettingsInLocaleStore(settings);
        }),
        catchError((error) => {
          this.store.dispatch(alertActions.showError({error: error}));
          return of(settingsActions.stopLoading());
        }),
      ),
    {dispatch: false},
  );

  fetchLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.fetchLogs),
      switchMap(() => {
        this.logger.info('Fetching Logs from locale store');
        return from(
          this.storage.get(EStorageKey.LOGS).then((value) => {
            return value;
          }),
        );
      }),
      map((logs) => {
        if (logs) {
          return settingsActions.fetchLogsSuccess({logs: logs});
        } else {
          return {type: 'NO_ACTION'};
        }
      }),
      catchError((error) => {
        this.store.dispatch(alertActions.showError({error: error}));
        return of(settingsActions.stopLoading());
      }),
    ),
  );

  saveLogs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(settingsActions.saveLogs),
        withLatestFrom(this.store.select(selectLogs)),
        switchMap(([action, logs]) => {
          return this.settingsService.saveLogsInLocaleStore(logs);
        }),
        catchError((error) => {
          this.store.dispatch(alertActions.showError({error: error}));
          return of(settingsActions.stopLoading());
        }),
      ),
    {dispatch: false},
  );

  setLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.setLanguage),
      switchMap((action) => {
        this.logger.info('Setting language');
        this.translateService.use(action.language);
        return of(settingsActions.saveSettings());
      }),
      catchError((error) => {
        this.store.dispatch(alertActions.showError({error: error}));
        return of(settingsActions.stopLoading());
      }),
    ),
  );

  setTeamCalendarCollapse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.toggleTeamCalendar),
      switchMap((action) => {
        this.logger.info('Setting team calendar collapse');
        return of(settingsActions.saveSettings());
      }),
      catchError((error) => {
        this.store.dispatch(alertActions.showError({error: error}));
        return of(settingsActions.stopLoading());
      }),
    ),
  );

  setShowSystemTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.showSystemTime),
      switchMap((action) => {
        this.logger.info('Setting show system time');
        return of(settingsActions.saveSettings());
      }),
      catchError((error) => {
        this.store.dispatch(alertActions.showError({error: error}));
        return of(settingsActions.stopLoading());
      }),
    ),
  );

  setCustomTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.useCustomTime),
      switchMap((action) => {
        this.logger.info('Setting custom time');
        this.store.dispatch(
          timeActions.useCustomTime({useCustomTime: action.useCustomTime}),
        );
        return of(settingsActions.saveSettings());
      }),
      catchError((error) => {
        this.store.dispatch(alertActions.showError({error: error}));
        return of(settingsActions.stopLoading());
      }),
    ),
  );

  addNewLogMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.addNewLog),
      switchMap((action) => {
        return of(settingsActions.saveLogs());
      }),
      catchError((error) => {
        this.store.dispatch(alertActions.showError({error: error}));
        return of(settingsActions.stopLoading());
      }),
    ),
  );

  sendLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.sendLogsToServer),
      withLatestFrom(
        this.store.select(selectServerTime),
        this.store.select(selectLogs),
        this.store.select(selectAccountFullName),
        this.store.select(selectLanguage),
      ),
      switchMap(([action, serverTime, logs, account, language]) => {
        this.logger.info('Sending logs to server');
        return this.settingsService
          .sendLogsToServer(action.message, serverTime, logs, account, language)
          .pipe(
            map(() => {
              return alertActions.showAlert({
                message: this.translateService.instant(
                  'CORE.MESSAGES.SUPPORT_FILE_SEND',
                ),
                messageType: EMessageType.INFORMATION,
              });
            }),
            catchError((error) => {
              this.store.dispatch(alertActions.showError({error: error}));
              return of(settingsActions.stopLoading());
            }),
          );
      }),
    ),
  );

  resetLogs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(settingsActions.resetLogs),
        switchMap(() => {
          this.logger.info('Resetting logs from locale store');
          return from(this.storage.remove(EStorageKey.LOGS));
        }),
        tap(() => {
          this.logger.info('Logs removed from locale store successfully');
        }),
        catchError((error) => {
          this.store.dispatch(alertActions.showError({error: error}));
          return of(settingsActions.stopLoading());
        }),
      ),
    {dispatch: false},
  );

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private settingsService: SettingsService,
    private storage: StorageService,
    private translateService: TranslateService,
    private store: Store,
  ) {}
}
