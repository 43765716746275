import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {Store} from '@ngrx/store';
import {alertActions} from 'src/app/features/alert/store/actions';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error: any) {
    let store = this.injector.get(Store);
    store.dispatch(alertActions.showError({error}));
  }
}
