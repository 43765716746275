import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {selectTimeStateSystemTime} from 'src/app/shared/store/time/selectors/time.selectors';
import {catchError, delay, map, of, switchMap, withLatestFrom} from 'rxjs';

import {employeeActions} from 'src/app/shared/store/employees/actions';
import {IEmployeesDataState} from 'src/app/shared/store/employees/types/employees-data-state';
import {NavController} from '@ionic/angular/standalone';
import {WorkTimeSchemeService} from './services/worktimescheme.service';
import {workTimeSchemeActions} from './actions';
import {TranslateService} from '@ngx-translate/core';
import {alertActions} from 'src/app/features/alert/store/actions';
import {EMessageType} from '../../enums/message-type.enum';
import {EAppRoutes} from '../../enums/app-routes.enum';

@Injectable()
export class WorkTimeSchemeFeatureEffects {
  setCarryOverRecord$ = createEffect(
    (actions$ = inject(Actions), navCtrl = inject(NavController)) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.setCarryOverRecord),
        map((action) => {
          if (action.payload) {
            navCtrl.navigateForward([
              EAppRoutes.CARRYOVER_RECORD,
              action.payload.id,
            ]);
          }
        }),
      ),
    {dispatch: false},
  );
  newWorkTimeScheme$ = createEffect(
    (
      actions$ = inject(Actions),
      workTimeSchemeService = inject(WorkTimeSchemeService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.addWorkTimeScheme),
        switchMap((action) => {
          return workTimeSchemeService
            .addNewWorkTimeScheme(action.payload)
            .pipe(
              map((response: IEmployeesDataState) => {
                store.dispatch(
                  employeeActions.successFetchingEmployees({
                    payload: response,
                  }),
                );
                return workTimeSchemeActions.addWorkTimeSchemeSuccess();
              }),
            );
        }),
        catchError((error: any) => {
          store.dispatch(alertActions.showError({error: error}));
          return of(workTimeSchemeActions.addWorkTimeSchemeFailure());
        }),
      ),
  );

  newWorkTimeSchemeSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),
      translateService = inject(TranslateService),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.addWorkTimeSchemeSuccess),
        map(() => {
          navCtrl.back();
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.STAFF.NEW_WORKTIME_SCHEME_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.STAFF.NEW_WORKTIME_SCHEME_SUCCESS',
          });
        }),
      ),
  );

  updateWorkTimeScheme$ = createEffect(
    (
      actions$ = inject(Actions),
      workTimeSchemeService = inject(WorkTimeSchemeService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.updateWorkTimeScheme),
        switchMap((action) => {
          return workTimeSchemeService
            .updateWorkTimeScheme(action.payload)
            .pipe(
              map((response: IEmployeesDataState) => {
                store.dispatch(
                  employeeActions.successFetchingEmployees({
                    payload: response,
                  }),
                );
                return workTimeSchemeActions.updateWorkTimeSchemeSuccess();
              }),
            );
        }),
        catchError((error: any) => {
          store.dispatch(alertActions.showError({error: error}));
          return of(workTimeSchemeActions.updateWorkTimeSchemeFailure());
        }),
      ),
  );

  updateWorkTimeSchemeSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),
      translateService = inject(TranslateService),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.updateWorkTimeSchemeSuccess),
        map(() => {
          navCtrl.back();
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.STAFF.EDIT_WORKTIME_SCHEME_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.STAFF.EDIT_WORKTIME_SCHEME_SUCCESS',
          });
        }),
      ),
  );

  deleteWorkTimeScheme$ = createEffect(
    (
      actions$ = inject(Actions),
      workTimeSchemeService = inject(WorkTimeSchemeService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.deleteWorkTimeScheme),
        switchMap((action) => {
          return workTimeSchemeService
            .deleteWorkTimeScheme(action.payload)
            .pipe(
              map((response: IEmployeesDataState) => {
                store.dispatch(
                  employeeActions.successFetchingEmployees({
                    payload: response,
                  }),
                );
                return workTimeSchemeActions.deleteWorkTimeSchemeSuccess();
              }),
            );
        }),
        catchError((error: any) => {
          store.dispatch(alertActions.showError({error: error}));
          return of(workTimeSchemeActions.deleteWorkTimeSchemeFailure());
        }),
      ),
  );

  deleteWorkTimeSchemeSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),
      translateService = inject(TranslateService),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.deleteWorkTimeSchemeSuccess),
        map(() => {
          navCtrl.back();
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.STAFF.DELETE_WORKTIME_SCHEME_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.STAFF.DELETE_WORKTIME_SCHEME_SUCCESS',
          });
        }),
      ),
  );

  deleteWorkTimeSchemeCard$ = createEffect(
    (
      actions$ = inject(Actions),
      workTimeSchemeService = inject(WorkTimeSchemeService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.deleteWorkTimeSchemeCard),
        switchMap((action) => {
          return workTimeSchemeService
            .deleteWorkTimeScheme(action.payload)
            .pipe(
              map((response: IEmployeesDataState) => {
                store.dispatch(
                  employeeActions.successFetchingEmployees({
                    payload: response,
                  }),
                );
                return workTimeSchemeActions.deleteWorkTimeSchemeCardSuccess();
              }),
            );
        }),
        catchError((error: any) => {
          store.dispatch(alertActions.showError({error: error}));
          return of(workTimeSchemeActions.deleteWorkTimeSchemeFailure());
        }),
      ),
  );

  deleteWorkTimeSchemeCardSuccess$ = createEffect(
    (actions$ = inject(Actions), translateService = inject(TranslateService)) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.deleteWorkTimeSchemeCardSuccess),
        map(() => {
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.STAFF.DELETE_WORKTIME_SCHEME_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.STAFF.DELETE_WORKTIME_SCHEME_SUCCESS',
          });
        }),
      ),
  );

  newCarryOverRecord$ = createEffect(
    (
      actions$ = inject(Actions),
      workTimeSchemeService = inject(WorkTimeSchemeService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.addCarryOverRecord),
        switchMap((action) => {
          return workTimeSchemeService
            .addNewCarryOverRecord(action.payload)
            .pipe(
              map((response: IEmployeesDataState) => {
                store.dispatch(
                  employeeActions.successFetchingEmployees({
                    payload: response,
                  }),
                );
                return workTimeSchemeActions.addCarryOverRecordSuccess();
              }),
            );
        }),
        catchError((error: any) => {
          store.dispatch(alertActions.showError({error: error}));
          return of(workTimeSchemeActions.addCarryOverRecordFailure());
        }),
      ),
  );

  newCarryOverRecordSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),
      translateService = inject(TranslateService),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.addCarryOverRecordSuccess),
        map(() => {
          navCtrl.back();
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.STAFF.NEW_TIME_CREDIT_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.STAFF.NEW_TIME_CREDIT_SUCCESS',
          });
        }),
      ),
  );

  updateCarryOverRecord$ = createEffect(
    (
      actions$ = inject(Actions),
      workTimeSchemeService = inject(WorkTimeSchemeService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.updateCarryOverRecord),
        switchMap((action) => {
          return workTimeSchemeService
            .updateCarryOverRecord(action.payload)
            .pipe(
              map((response: IEmployeesDataState) => {
                store.dispatch(
                  employeeActions.successFetchingEmployees({
                    payload: response,
                  }),
                );
                return workTimeSchemeActions.updateCarryOverRecordSuccess();
              }),
            );
        }),
        catchError((error: any) => {
          store.dispatch(alertActions.showError({error: error}));
          return of(workTimeSchemeActions.updateCarryOverRecordFailure());
        }),
      ),
  );

  updateCarryOverRecordSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      navCtrl = inject(NavController),
      translateService = inject(TranslateService),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.updateCarryOverRecordSuccess),
        map(() => {
          navCtrl.back();
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.STAFF.EDIT_TIME_CREDIT_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.STAFF.EDIT_TIME_CREDIT_SUCCESS',
          });
        }),
      ),
  );

  deleteCarryOverRecord$ = createEffect(
    (
      actions$ = inject(Actions),
      workTimeSchemeService = inject(WorkTimeSchemeService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.deleteCarryOverRecord),
        switchMap((action) => {
          return workTimeSchemeService
            .deleteCarryOverRecord(action.payload)
            .pipe(
              map((response: IEmployeesDataState) => {
                store.dispatch(
                  employeeActions.successFetchingEmployees({
                    payload: response,
                  }),
                );
                return workTimeSchemeActions.deleteCarryOverRecordSuccess();
              }),
            );
        }),
        catchError((error: any) => {
          store.dispatch(alertActions.showError({error: error}));
          return of(workTimeSchemeActions.deleteCarryOverRecordFailure());
        }),
      ),
  );

  deleteCarryOverRecordSuccess$ = createEffect(
    (
      actions$ = inject(Actions),
      translateService = inject(TranslateService),
      navCtrl = inject(NavController),
    ) =>
      actions$.pipe(
        ofType(workTimeSchemeActions.deleteCarryOverRecordSuccess),
        map(() => {
          navCtrl.back();
          return alertActions.showAlert({
            message: translateService.instant(
              'PAGES.STAFF.DELETE_TIME_CREDIT_SUCCESS',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'PAGES.STAFF.DELETE_TIME_CREDIT_SUCCESS',
          });
        }),
      ),
  );

  workTimeSchemeFailedActions$ = createEffect(
    (actions$ = inject(Actions), navCtrl = inject(NavController)) =>
      actions$.pipe(
        ofType(
          workTimeSchemeActions.addWorkTimeSchemeFailure,
          workTimeSchemeActions.updateWorkTimeSchemeFailure,
          workTimeSchemeActions.deleteWorkTimeSchemeFailure,
          workTimeSchemeActions.addCarryOverRecordFailure,
          workTimeSchemeActions.updateCarryOverRecordFailure,
          workTimeSchemeActions.deleteCarryOverRecordFailure,
        ),
        map(() => {
          navCtrl.back();
        }),
      ),
    {dispatch: false},
  );
}
