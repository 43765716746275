import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, tap, withLatestFrom} from 'rxjs';
import {EAppRoutes} from '../../enums/app-routes.enum';
import {routerNavigationAction} from '@ngrx/router-store';
import {FlowControlService} from '../../services/flow-control.service';
import {Store} from '@ngrx/store';
import {selectAccount} from '../account/reducer';
import {selectTimeStateCurrentMonth} from '../time/selectors/time.selectors';

@Injectable()
export class APIFeatureEffect {
  prefetchEmployees$ = createEffect(
    (
      actions$ = inject(Actions),
      flowControlService = inject(FlowControlService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(routerNavigationAction),
        filter(({payload}) => payload.routerState.url === EAppRoutes.HOME),
        tap(() => {
          flowControlService.homePage();
        }),
      ),
    {dispatch: false},
  );

  prefetchUsers$ = createEffect(
    (
      actions$ = inject(Actions),
      flowControlService = inject(FlowControlService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(routerNavigationAction),
        filter(({payload}) => payload.routerState.url === EAppRoutes.USERS),
        tap(() => {
          flowControlService.usersPage();
        }),
      ),
    {dispatch: false},
  );

  calendarHandler$ = createEffect(
    (
      actions$ = inject(Actions),
      flowControlService = inject(FlowControlService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(routerNavigationAction),
        filter(({payload}) => payload.routerState.url === EAppRoutes.CALENDAR),
        withLatestFrom(store.select(selectTimeStateCurrentMonth)),
        tap(([action, month]) => {
          flowControlService.calendarPage(month);
        }),
      ),
    {dispatch: false},
  );

  absencesHandler$ = createEffect(
    (
      actions$ = inject(Actions),
      flowControlService = inject(FlowControlService),
      store = inject(Store),
    ) =>
      actions$.pipe(
        ofType(routerNavigationAction),
        filter(({payload}) => payload.routerState.url === EAppRoutes.ABSENCES),
        tap((url) => {
          flowControlService.absencesPage();
        }),
      ),
    {dispatch: false},
  );
}
