import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {IonAlert} from '@ionic/angular/standalone';
import {combineLatest, tap} from 'rxjs';
import {selectAlertState} from './store/reducer';
import {CommonModule} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {EMessageType} from '../../shared/enums/message-type.enum';
import {IAlertState} from './store/types/alert-state.interface';
import {AlertButton} from '@ionic/angular';
import {alertActions} from './store/actions';
import {settingsActions} from 'src/app/shared/store/settings/actions';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonAlert, CommonModule],
})
export class AlertComponent {
  store = inject(Store);
  translateService = inject(TranslateService);
  header = '';
  subHeader = '';
  message = '';
  htmlAttribute: {[key: string]: any} | null = null;
  buttons: AlertButton[] = [];
  cssClasses: string[] = [];
  showInput = false;
  alertInputs: any = [];
  data$ = combineLatest({
    alert: this.store.select(selectAlertState),
  }).pipe(
    tap((data) => {
      this.header = this.getAlertTitel(data.alert.type);
      this.subHeader = this.getAlertSubHeader(data.alert);
      this.message = this.getAlertMessage(data.alert);
      this.buttons = this.getAlertButtons(data.alert.type);
      this.cssClasses = this.getAlertCssClasses(data.alert.type);
      this.htmlAttribute = data.alert.id ? {'data-test': data.alert.id} : null;
      this.showInput = data.alert.type == EMessageType.SUPPORT_FILE;

      if (this.showInput) {
        this.alertInputs = [
          {
            name: 'commentSection',
            type: 'textarea',
            placeholder: this.translateService.instant(
              'CORE.MESSAGES.SUPPORT_FILE_INPUT_PLACEHOLDER',
            ),
            attributes: {
              minlength: 0,
              maxlength: 255,
            },
          },
        ];
      } else {
        this.alertInputs = [];
      }
    }),
  );

  private getAlertTitel(type: EMessageType): string {
    let title = '';
    switch (type) {
      case EMessageType.INFORMATION:
        title = this.translateService.instant('CORE.MESSAGES.INFORMATION');
        break;
      case EMessageType.WARNING:
        title = this.translateService.instant('CORE.MESSAGES.WARNING');
        break;
      case EMessageType.ERROR:
      case EMessageType.SUPPORT_FILE:
        title = this.translateService.instant('CORE.MESSAGES.ERROR');
        break;
      case EMessageType.UPDATE:
        title = this.translateService.instant('CORE.MESSAGES.INFORMATION');
        break;
    }

    return title;
  }

  private getAlertMessage(state: IAlertState): string {
    let message = state.message;
    switch (state.type) {
      case EMessageType.SUPPORT_FILE:
        message = this.translateService.instant('CORE.MESSAGES.NOTE');
        break;
    }

    return message;
  }

  private getAlertSubHeader(state: IAlertState): string {
    let subHeader = '';
    switch (state.type) {
      case EMessageType.UPDATE:
        subHeader = this.translateService.instant('CORE.MESSAGES.NEW_UPDATE');
        break;
      case EMessageType.SUPPORT_FILE:
        subHeader = state.message;
        break;
    }

    return subHeader;
  }

  private getAlertCssClasses(type: EMessageType): string[] {
    let cssClass: string[] = ['alert-app-messages'];
    switch (type) {
      case EMessageType.INFORMATION:
        cssClass.push('alert-info-message');
        break;
      case EMessageType.WARNING:
        cssClass.push('alert-warning-message');
        break;
      case EMessageType.ERROR:
      case EMessageType.SUPPORT_FILE:
        cssClass.push('alert-error-message');
        break;
      case EMessageType.UPDATE:
        cssClass.push('alert-info-message');
        break;
    }

    return cssClass;
  }

  private getAlertButtons(type: EMessageType): AlertButton[] {
    let alertButtons: AlertButton[] = [
      {
        text: this.translateService.instant('CORE.ACTIONS.OK'),
        role: 'confirm',
        handler: () => {
          this.store.dispatch(alertActions.closeAlert());
        },
      },
    ];

    if (type == EMessageType.SUPPORT_FILE) {
      alertButtons = [
        {
          text: this.translateService.instant('CORE.ACTIONS.CANCEL'),
          role: 'cancel',
          handler: () => {
            this.store.dispatch(alertActions.closeAlert());
          },
        },
        {
          text: this.translateService.instant('CORE.ACTIONS.SEND'),
          role: 'confirm',
          handler: (data: any) => {
            const comment = data.commentSection;
            this.store.dispatch(alertActions.closeAlert());
            this.store.dispatch(
              settingsActions.sendLogsToServer({message: comment}),
            );
          },
        },
      ];
    }

    if (type == EMessageType.UPDATE) {
      alertButtons = [
        {
          text: this.translateService.instant('CORE.ACTIONS.UPDATE'),
          role: 'confirm',
          handler: () => {
            this.store.dispatch(alertActions.closeAlert());
            window.location.reload();
          },
        },
      ];
    }

    return alertButtons;
  }
}
