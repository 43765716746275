import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {alertActions} from './actions';
import {delay, map} from 'rxjs';
import {ErrorHandlerService} from 'src/app/shared/services/error-handler.service';

@Injectable()
export class AlertFeatureEffect {
  showError$ = createEffect(
    (actions$ = inject(Actions), errorService = inject(ErrorHandlerService)) =>
      actions$.pipe(
        ofType(alertActions.showError),
        map((action) => {
          let errorWrapper: any = errorService.handleError(action.error);
          return alertActions.showAlertWithInput(errorWrapper);
        }),
      ),
  );

  closeAlert$ = createEffect((actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(alertActions.closeAlert),
      delay(100),
      map(() => {
        return alertActions.reset();
      }),
    ),
  );
}
