import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {usersActions} from './actions';
import {catchError, delay, map, of, switchMap} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {UsersService} from './services/users.service';
import {NavController} from '@ionic/angular/standalone';
import {EAppRoutes} from '../../enums/app-routes.enum';
import {Store} from '@ngrx/store';
import {alertActions} from 'src/app/features/alert/store/actions';
import {TranslateService} from '@ngx-translate/core';
import {EMessageType} from '../../enums/message-type.enum';

@Injectable()
export class UsersFeatureEffect {
  fetchUsersEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      logger = inject(NGXLogger),
      usersService = inject(UsersService),
      store = inject(Store),
    ) => {
      return actions$.pipe(
        ofType(usersActions.fetchUsers),
        switchMap((action) => {
          return usersService.getUsers().pipe(
            delay(200),
            map((users) => {
              logger.info('Fetch users successful');
              return usersActions.fetchUsersSuccess({users});
            }),
            catchError((error) => {
              store.dispatch(alertActions.showError({error: error}));
              return of(usersActions.fetchUsersFailure());
            }),
          );
        }),
      );
    },
  );

  fetchUsersFailedEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      logger = inject(NGXLogger),
      navCtrl = inject(NavController),
    ) => {
      return actions$.pipe(
        ofType(usersActions.fetchUsersFailure),
        map(() => {
          logger.info('Fetch users failed');
          navCtrl.navigateBack([EAppRoutes.HOME], {animated: false});
        }),
      );
    },
    {dispatch: false},
  );

  addUserEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      logger = inject(NGXLogger),
      usersService = inject(UsersService),
      store = inject(Store),
    ) => {
      return actions$.pipe(
        ofType(usersActions.addUser),
        switchMap((action) => {
          return usersService.createUser(action.user).pipe(
            map((user) => {
              logger.info('Add user successful');
              return usersActions.addUserSuccess({user});
            }),
            catchError((error) => {
              store.dispatch(alertActions.showError({error: error}));
              return of(usersActions.addUserFailure());
            }),
          );
        }),
      );
    },
  );

  addUserSuccessEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      translateService = inject(TranslateService),
      navCtrl = inject(NavController),
    ) => {
      return actions$.pipe(
        ofType(usersActions.addUserSuccess),
        map(() => {
          navCtrl.navigateBack([EAppRoutes.USERS], {animated: false});
          return alertActions.showAlert({
            message: translateService.instant('CORE.MESSAGES.USER_ADDED'),
            messageType: EMessageType.INFORMATION,
            id: 'CORE.MESSAGES.USER_ADDED',
          });
        }),
      );
    },
  );

  // addUserFailedEffect$ = createEffect(
  //   (actions$ = inject(Actions), navCtrl = inject(NavController)) => {
  //     return actions$.pipe(
  //       ofType(usersActions.addUserFailure),
  //       map(() => {
  //         navCtrl.navigateBack([EAppRoutes.USERS], {animated: false});
  //       }),
  //     );
  //   },
  //   {dispatch: false},
  // );

  updateUserEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      logger = inject(NGXLogger),
      usersService = inject(UsersService),
      store = inject(Store),
    ) => {
      return actions$.pipe(
        ofType(usersActions.updateUser),
        switchMap((action) => {
          return usersService.updateUser(action.id, action.user).pipe(
            map((user) => {
              logger.info('Update user successful');
              return usersActions.updateUserSuccess({user});
            }),
            catchError((error) => {
              store.dispatch(alertActions.showError({error: error}));
              return of(usersActions.updateUserFailure());
            }),
          );
        }),
      );
    },
  );

  updateUserSuccessEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      translateService = inject(TranslateService),
      navCtrl = inject(NavController),
    ) => {
      return actions$.pipe(
        ofType(usersActions.updateUserSuccess),
        map(() => {
          navCtrl.navigateBack([EAppRoutes.USER], {animated: false});
          return alertActions.showAlert({
            message: translateService.instant(
              'CORE.MESSAGES.USER_DATA_UPDATED',
            ),
            messageType: EMessageType.INFORMATION,
            id: 'CORE.MESSAGES.USER_DATA_UPDATED',
          });
        }),
      );
    },
  );

  // updateUserFailedEffect$ = createEffect(
  //   (actions$ = inject(Actions), navCtrl = inject(NavController)) => {
  //     return actions$.pipe(
  //       ofType(usersActions.updateUserFailure),
  //       map(() => {
  //         navCtrl.navigateBack([EAppRoutes.USER], {animated: false});
  //       }),
  //     );
  //   },
  //   {dispatch: false},
  // );

  deleteUserEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      logger = inject(NGXLogger),
      usersService = inject(UsersService),
      store = inject(Store),
    ) => {
      return actions$.pipe(
        ofType(usersActions.deleteUser),
        switchMap((action) => {
          return usersService.deleteUser(action.id).pipe(
            map(() => {
              logger.info('Delete user successful');
              return usersActions.deleteUserSuccess({id: action.id});
            }),
            catchError((error) => {
              store.dispatch(alertActions.showError({error: error}));
              return of(usersActions.deleteUserFailure());
            }),
          );
        }),
      );
    },
  );

  deleteUserSuccessEffect$ = createEffect(
    (
      actions$ = inject(Actions),
      translateService = inject(TranslateService),
      navCtrl = inject(NavController),
    ) => {
      return actions$.pipe(
        ofType(usersActions.deleteUserSuccess),
        map(() => {
          navCtrl.navigateBack([EAppRoutes.USERS], {animated: false});
          return alertActions.showAlert({
            message: translateService.instant('CORE.MESSAGES.USER_DELETED'),
            messageType: EMessageType.INFORMATION,
            id: 'CORE.MESSAGES.USER_DELETED',
          });
        }),
      );
    },
  );

  // deleteUserFailedEffect$ = createEffect(
  //   (actions$ = inject(Actions), navCtrl = inject(NavController)) => {
  //     return actions$.pipe(
  //       ofType(usersActions.deleteUserFailure),
  //       map(() => {
  //         navCtrl.navigateBack([EAppRoutes.USER], {animated: false});
  //       }),
  //     );
  //   },
  //   {dispatch: false},
  // );
}
