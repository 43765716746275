@if (data$ | async; as data) {
  <ion-alert
    [isOpen]="data.alert.isOpen"
    [header]="header"
    [subHeader]="subHeader"
    [message]="message"
    [cssClass]="cssClasses"
    [buttons]="buttons"
    [htmlAttributes]="htmlAttribute"
    [inputs]="alertInputs"
    backdropDismiss="false"></ion-alert>
}
